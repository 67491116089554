import {
    Abstract
} from './Abstract';

export class CreditNoteRelatedInvoices extends Abstract {
    constructor(session) {
        super('credit_note/CreditNoteManager', session);
        this.id_credit_note = null;
        this.id_invoice = null;
        this.uuid_invoice = null;
    }
}
