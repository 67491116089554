<template>
    <div class="card">
        <Dialog v-model:visible="customerDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <CustomerUI @update="updateCustomer" @save="saveCustomer" :id="entity.id_customer" :modal="true" />
        </Dialog>
        <Dialog v-model:visible="serieDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <SerieUI :voucher_type="'E'" @save="saveSerie" :modal="true" />
        </Dialog>
        <Loader v-model="loading" />
        <Helper v-model="conceptHelper" header="Buscar Concepto" :headers="conceptHelperHeaders" :rows="concepts" @selected="selectConceptHelper" />
        <Helper v-model="customerHelper" header="Buscar Cliente" :headers="customersHeaders" :rows="customers" @selected="selectCustomer" />
        <Helper v-model="invoiceHelper" header="Buscar CFDI / Factura Relacionada" :headers="invoicesHeaders" :rows="invoicesByRFC" @selected="selectInvoice"/>
        <Helper v-model="creditNoteHelper" header="Buscar Nota Crédito" :headers="creditNoteHeaders" :rows="creditNotesBySerie" @selected="selectCreditNote" />
        <Email @error="errorEmail"  @sended="sendedEmail" v-model="emailDialog" :document="entity.id" :title="emailTitle" :key_name="'ENVIO_NOTACREDITO'" :to="entity.receiver_email"  />
        <Dialog :style="{ width: '75%' }" :closeOnEscape="true" :closable="true" :modal="true" header="Modificar Documento Relacionado" v-model:visible="relatedInvoiceDialog">
            <div class="p-fluid formgrid grid">
                <label>UUID</label>
                <div class="p-inputgroup">
                    <InputText v-model="relatedInvoiceUpdate.uuid_invoice" :class="{ 'p-invalid': validate_relatedInvoices_update.validations.uuid_invoice === false }" />
                    <Button @click="invoiceHelper.visible = true" icon="pi pi-search" class="p-button-primary" />
                    <Button @click="actualizaRelatedInvoices">Actualizar</Button>
                </div>
                <small class="p-invalid" v-if="validate_relatedInvoices_update.validations.uuid_invoice === false"> Favor de llenar el campo</small>
            </div>
        </Dialog>
        <Toast />
        <Panel header="Nota de Crédito">
            <BasicFormToolbar :actions="['new','save','delete']" @delete="deleteEntity" @new="openNew" @save="save">
                <template #custom-left>
                    <Button label="Enviar Correo" icon="pi pi-envelope" class="p-button-warning mr-2" @click="sendEmail" />
                    <Button label="Timbrar XML" icon="pi pi-globe" class="p-button-warning mr-2" @click="certificateInvoice" />
                </template>
            </BasicFormToolbar>
            <div class="grid">
                <div class="col-12">
                    <TabView>
                        <TabPanel header="Informacion General">
                            <Fieldset :toggleable="true" legend="Informacion General">
                            <div class="p-fluid formgrid grid">
                                <FormDropdown @change="seriesChanged" wrapperClass="field col-2"  v-model="entity.series" :disabled="entity.id != null" :valid="validate.validations.series" :options="series" :optionLabel="'serie'" :optionValue="'serie'" 
                                :add="true" @add="serieDialog = true" :label="'Serie'" />
                                <FormInputText v-model="entity.id_credit_note" :label="'Folio'" :wrapperClass="'field col-2'" :search="true" @search="creditNoteHelper.visible = true" />
                                <FormInputSwitch label="CFDI Global" :wrapperClass="'field col-1'" v-model="is_global" />
                                <div class="col-2">
                                    <FileIcon width="3.5rem" extension="xml" @click="downloadXML" />
                                    <FileIcon width="3.5rem" extension="pdf" @click="downloadPDF"/>
                                </div>
                                <FormCalendar v-model="entity.date" :valid="validate.validations.date" :label="'Fecha'" :wrapperClass="'field col-2 col-offset-3'" />
                                <FormDropdownComplex :labelInOption="'c_FormaPago - Descripcion'" :labelInValue="'c_FormaPago - Descripcion'" :wrapperClass="'field col-4'" label="Forma de Pago" v-model="entity.payment_form" :options="c_FormaPago" optionLabel="c_FormaPago" optionValue="c_FormaPago" />
                                <FormDropdownComplex :labelInOption="'c_MetodoPago - Descripcion'" :labelInValue="'c_MetodoPago'" :wrapperClass="'field col-2'" label="Metodo de Pago" v-model="entity.payment_method" :options="c_MetodoPago" optionLabel="c_MetodoPago" optionValue="c_MetodoPago" />
                                <FormInputText v-model="entity.expedition_place" wrapperClass="field col-2" label="Lugar de Expedicion" :valid="validate.validations.expedition_place"/>
                                <FormDropdownComplex :labelInOption="'c_TipoDeComprobante - Descripcion'" :labelInValue="'Descripcion'" :wrapperClass="'field col-2'" label="Tipo" v-model="entity.voucher_type" :options="c_TipoDeComprobante" optionLabel="c_TipoDeComprobante" optionValue="c_TipoDeComprobante" />
                                <FormDropdownComplex :labelInOption="'c_Exportacion - Descripcion'" :labelInValue="'Descripcion'" :wrapperClass="'field col-2'" label="Exportacion" v-model="entity.export" :options="c_Exportacion" optionLabel="c_Exportacion" optionValue="c_Exportacion" />
                                <FormDropdownComplex :labelInOption="'c_Moneda - Descripcion'" :labelInValue="'c_Moneda'" :wrapperClass="'field col-3'" label="Moneda" v-model="entity.currency" :options="c_Moneda" optionLabel="c_Moneda" optionValue="c_Moneda" />
                                <FormInputNumber label="Tasa de Cambio" :disabled="entity.currency == 'MXN'" wrapperClass="field col-3" :valid="validate.validations.exchange_rate" v-model="entity.exchange_rate" mode="currency" />
                                <FormInputNumber label="Retenidos" wrapperClass="field col-2 col-offset-2" v-model="entity.taxes_total_detained" mode="currency" :disabled="true"/>
                                <FormInputNumber label="Trasladados" wrapperClass="field col-2" v-model="entity.taxes_total_transferred" mode="currency" :disabled="true"/>
                                <FormInputNumber label="SubTotal" wrapperClass="field col-3" v-model="entity.subtotal" mode="currency" :disabled="true"/>
                                <FormInputNumber label="Descuento" wrapperClass="field col-3" v-model="entity.discount" mode="currency" :disabled="true"/>
                                <FormInputNumber label="Impuestos" wrapperClass="field col-3" v-model="entity.taxes_total" mode="currency" :disabled="true"/>
                                <FormInputNumber label="Total" wrapperClass="field col-3" v-model="entity.total" mode="currency" :disabled="true"/>
                            </div>
                            </Fieldset>
                        </TabPanel>
                        <TabPanel header="Documentos Relacionados">
                            <Fieldset :toggleable="true" legend="Documentos Relacionados">
                            <div class="p-fluid formgrid grid">
                                <div class="field col-5">
                                    <label>UUID</label>
                                    <div class="p-inputgroup">
                                        <InputText v-model="relatedInvoices.uuid_invoice" :class="{ 'p-invalid': validate_relatedInvoices.validations.uuid_invoice === false }" />
                                        <Button @click="invoiceHelper.visible = true" icon="pi pi-search" class="p-button-primary" />
                                        <Button @click="saveRelatedInvoices" icon="pi pi-save" label="Guardar" />
                                    </div>
                                    <small class="p-invalid" v-if="validate_relatedInvoices.validations.uuid_invoice === false"> Favor de llenar el campo</small>
                                </div>
                            </div>
                            <div class="field col-12">
                                <BasicDatatable
                                :exportable="false"
                                :headerVisible="false" 
                                :gridlines="true"
                                :footerVisible="false"
                                @selected="selectRelatedInvoice" 
                                :headers="relatedInvoicesHeaders" 
                                :rows="entity.credit_note_related_invoices" />
                                </div>
                            </Fieldset>
                        </TabPanel>
                    </TabView>
                </div>
                <br />
                <div class="col-6">
                    <ComprobanteIssuer :c_RegimenFiscal="c_RegimenFiscal" :entity="entity" :certificate="branch.certificate" />
                </div>
                <div class="col-6">
                    <Fieldset :toggleable="true" :collapsed="true" legend="Informacion Receptor">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12">
                                <label for="">RFC</label>
                                <div class="p-inputgroup">
                                    <InputText @keydown.enter.exact="searchByRFC" v-model="entity.receiver_rfc" :class="{ 'p-invalid': validate.validations.receiver_rfc === false }" />
                                    <Button v-tooltip.top="{value:'Seleccionar cliente', class: 'custom-tool'}" @click="customerHelper.visible = true" icon="pi pi-search" class="p-button-primary" />
                                    <Button v-tooltip.top="{value:'Crear cliente', class: 'custom-tool'}" @click="customerDialog = true" icon="pi pi-plus" class="p-button-warning" />
                                    <Button v-tooltip.top="{value:'Limpiar datos', class: 'custom-tool'}" @click="newCustomer" icon="pi pi-refresh" class="p-button-info" />
                                </div>
                                <small class="p-invalid" v-if="validate.validations.receiver_rfc === false"> El campo de RFC solo puede tener 12 o 13 caracteres </small>
                            </div>
                            <FormInputText v-model="entity.receiver_name" wrapperClass="field col-12" label="Nombre / Razon Social" :valid="validate.validations.receiver_name"/>
                            <FormDropdownComplex :valid="validate.validations.receiver_tax_regime" :labelInOption="'c_RegimenFiscal - Descripcion'" :labelInValue="'c_RegimenFiscal - Descripcion'" :wrapperClass="'field col-12'" label="Regimen Fiscal" v-model="entity.receiver_tax_regime" :options="regimenFiscalReceptor" optionLabel="c_RegimenFiscal" optionValue="c_RegimenFiscal" />
                            <FormInputText v-model="entity.receiver_tax_residence" wrapperClass="field col-12" label="Direccion"/>
                            <FormInputText v-model="entity.receiver_postal_code" :valid="validate.validations.receiver_postal_code" wrapperClass="field col-12" label="Codigo Postal"/>
                            <FormDropdownComplex :valid="validate.validations.receiver_credit_note_use" :labelInOption="'c_UsoCFDI - Descripcion'" :labelInValue="'c_UsoCFDI - Descripcion'" :wrapperClass="'field col-12'" label="Uso CFDI" v-model="entity.receiver_credit_note_use" :options="c_UsoCFDI" optionLabel="c_UsoCFDI" optionValue="c_UsoCFDI" />
                            <FormInputText v-model="entity.receiver_email" wrapperClass="field col-12" label="Correo Electronico" :valid="validate.validations.receiver_email" validation="Favor de llenar el campo, ademas usar formato de correo"/>
                        </div>
                    </Fieldset>
                </div>
                    <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
                    <div v-if="is_global" class="col-12">
                        <Fieldset :toggleable="true" :collapsed="20" legend="Información Global">
                            <div class="p-fluid formgrid grid">
                                <FormDropdownComplex :valid="validate.validations.global_information_periodicity" :labelInOption="'c_Periodicidad - Descripcion'" :labelInValue="'c_Periodicidad - Descripcion'" :wrapperClass="'field col-4'" label="Periodicidad" v-model="entity.global_information_periodicity" :options="c_Periodicidad" optionLabel="c_Periodicidad" optionValue="c_Periodicidad" />
                                <FormDropdownComplex :valid="validate.validations.global_information_months" :labelInOption="'c_Meses - Descripcion'" :labelInValue="'c_Meses - Descripcion'" :wrapperClass="'field col-4'" label="Mes" v-model="entity.global_information_months" :options="c_Meses" optionLabel="c_Meses" optionValue="c_Meses" />
                                <FormDropdownComplex :valid="validate.validations.global_information_anio" :labelInOption="'c_Anios - Descripcion'" :labelInValue="'c_Anios - Descripcion'" :wrapperClass="'field col-4'" label="Año" v-model="entity.global_information_anio" :options="c_Anios" optionLabel="c_Anios" optionValue="c_Anios" />
                            </div>
                        </Fieldset>
                    </div>
                    </FadeInOut>
                <div class="col-12">
                    <ComprobanteConcept @delete="deleteConcept" @save="saveConcepto" @update="updateConcepto" :c_ClaveUnidad="c_ClaveUnidad" :c_ObjetoImp="c_ObjetoImp" :concepts="entity.credit_note_concepts" version="credit_note" />
                </div>
                <div class="col-6">
                    <Fieldset :toggleable="true" :collapsed="true" legend="Timbrado">
                        <div class="p-fluid formgrid grid">
                            <FormInputText wrapperClass="field col-12" :readonly="true" :label="'UUID'" />
                            <div class="field col-12">
                                <label>Estatus</label>
                                <InputText :value="getEstatus(entity)" :readonly="true" />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div class="col-6">
                    <Fieldset :toggleable="true" :collapsed="true" legend="Cancelacion">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12">
                                <label>Motivo</label>
                                <Dropdown
                                    @beforeShow="concept.cancelled_relation = null"
                                    :filter="true"
                                    v-model="concept.cancelled_relation"
                                    :virtualScrollerOptions="{ itemSize: 20 }"
                                    :options="c_TipoRelacionCancelacion"
                                    optionLabel="Descripcion"
                                    optionValue="c_TipoRelacionCancelacion"
                                >
                                    <template #option="slotProps">
                                        <div>
                                            <div>{{ slotProps.option.c_TipoRelacionCancelacion }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                            <div class="field col-12">
                                <label>UUID's</label>
                                <InputText v-model="entity.closed_by" :readonly="true" />
                            </div>
                            <div class="field col-12">
                                <Button class="p-button-danger" icon="pi pi-times-circle" label="Cancelar" />
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>
        </Panel>
    </div>
</template>

<script>
import ComprobanteIssuer from "./Components/ComprobanteIssuer.vue";
import ComprobanteConcept from "./Components/ComprobanteConcept.vue";
import CustomerUI from "../../Comercial/Catalogos/Customer.vue";
import { CreditNote } from '../../../models/cfdi40/CreditNote';
import { CreditNoteConcept } from '../../../models/cfdi40/CreditNoteConcept';
import { CreditNoteRelatedInvoices } from '../../../models/cfdi40/CreditNoteRelatedInvoices';

import { Branch } from '../../../models/general/Branch';
import { Rule, fillObject, ErrorToast, satCatalogo, HeaderGrid, validate } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import Email from '../../../components/general/EmailForm.vue';

import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import FormDropdown from '../../../components/general/FormDropdown.vue';
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';

import FormCalendar from '../../../components/general/FormCalendar.vue';
import FormInputSwitch from '../../../components/general/FormInputSwitch.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FileIcon from "../../../components/general/FileIcon.vue";

import Session from '../../../mixins/sessionMixin';
import SerieUI from "../../Comercial/Catalogos/Serie.vue";

import { Customer } from '../../../models/comercial/Customer';
import { InvoiceSerie } from '../../../models/comercial/InvoiceSerie';
import { Invoice } from '../../../models/cfdi40/Invoice';
import { BranchCertificate } from '../../../models/general/BranchCertificate';


export default {
    mixins: [Session],
    props: {
        id: null,
        view: null,
    },
    data() {
        return {
            serieDialog: false,
            is_global: false,
            deleteDialog: false,
            entity: null,
            conceptUpdate: new CreditNoteConcept(),
            conceptDelete:  new CreditNoteConcept(),
            relatedInvoiceUpdate: new CreditNoteRelatedInvoices(),
            entities: [],
            customers: [],
            creditNotes: [],
            prodServs: [],
            invoices: [],
            series: [],
            emailDialog: {
                visible: false,
                html: '',
            },
            conceptDialog: false,
            relatedInvoiceDialog: false,
            customersHeaders: [
                new HeaderGrid('Nombre', 'name'),
                new HeaderGrid('Apellidos', 'last_name'),
                new HeaderGrid('RFC', 'rfc'),
                new HeaderGrid('Direccion', 'address'),
                new HeaderGrid('CP', 'postal_code'),
                new HeaderGrid('Pais', 'country'),
                new HeaderGrid('Estado', 'state'),
                new HeaderGrid('Municipio', 'municipality'),
                new HeaderGrid('Colonia', 'suburb'),
                new HeaderGrid('Regimen Fiscal', 'tax_regime'),
            ],
             invoicesHeaders: [
                new HeaderGrid('Serie', 'series'),
                new HeaderGrid('Folio', 'id_invoice'), 
                new HeaderGrid('Receptor', 'receiver_name'),
                new HeaderGrid('UUID', 'uuid'),
            ],
            creditNoteHeaders: [new HeaderGrid('Serie', 'series'), new HeaderGrid('Folio', 'id_credit_note'), new HeaderGrid('Receptor', 'receiver_name'), new HeaderGrid('RFC Receptor', 'receiver_rfc'), new HeaderGrid('Total', 'total', { type: "currency"})],
            conceptsHeaders: [
                new HeaderGrid('#', 'id_number'), 
                new HeaderGrid('Cantidad', 'quantity'), 
                new HeaderGrid('Unidad', 'unit_name'), 
                new HeaderGrid('Descripcion', 'description'), 
                new HeaderGrid('P.U.', 'unit_value' , { type: 'currency'}),
                new HeaderGrid('Subtotal', 'subtotal' , { type: 'currency', function: 'SUM', formula: 'evaluate', expression: 'unit_value * quantity'}),
                new HeaderGrid('IVA', 'iva' , { type: 'percentage'}) , 
                new HeaderGrid('IEPS', 'ieps' , { type: 'percentage'}) ,
                new HeaderGrid('Total', 'final_total' , { type: 'currency', function: 'SUM', formula: 'evaluate', expression: '(unit_value * quantity) + (unit_value * quantity * iva%) + (unit_value * quantity * ieps%)'}),
            ],
            relatedInvoicesHeaders: [new HeaderGrid('UUID', 'uuid_invoice'),],
            branch: new Branch(this.session),
            concept: new CreditNoteConcept(this.session),
            relatedInvoices: new CreditNoteRelatedInvoices(this.session),
            c_MetodoPago: [],
            c_FormaPago: [],
            c_TipoDeComprobante: [{ c_TipoDeComprobante: 'E', Descripcion: 'Egreso' },],
            c_Exportacion: [],
            c_Moneda: [],
            c_RegimenFiscal: [],
            c_UsoCFDI: [],
            c_ClaveUnidad: [],
            c_TipoRelacion: [],
            c_Periodicidad: [],
            c_Meses: [],
            c_TipoRelacionCancelacion: [],
            c_ObjetoImp: [
                { c_ObjetoImp: '01', Descripcion: 'No objeto de impuesto.' },
                { c_ObjetoImp: '02', Descripcion: 'Sí objeto de impuesto.' },
                { c_ObjetoImp: '03', Descripcion: 'Sí objeto del impuesto y no obligado al desglose.' },
            ],
            c_Impuestos: [
                { c_Impuestos: '01', Descripcion: 'ISR' },
                { c_Impuestos: '02', Descripcion: 'IVA' },
                { c_Impuestos: '03', Descripcion: 'IEPS' },
            ],
            c_Anios: [
            {c_Anios: '2021', Descripcion: '2021'},
			{c_Anios: '2022', Descripcion: '2022'},
			{c_Anios: '2023', Descripcion: '2023'},
            ],
            rules: [
                new Rule({ name: 'series' }),
                new Rule({ name: 'payment_form' }),
                new Rule({ name: 'currency' }),
                new Rule({ name: 'exchange_rate' }),
                new Rule({ name: 'voucher_type' }),
                new Rule({ name: 'export' }),
                new Rule({ name: 'payment_method' }),
                new Rule({ name: 'expedition_place' }),
                new Rule({ name: 'issuing_rfc', type: 'rfc' }),
                new Rule({ name: 'issuing_name' }),
                new Rule({ name: 'issuing_tax_regime' }),
                new Rule({ name: 'receiver_rfc', type: 'rfc' }),
                new Rule({ name: 'receiver_name' }),
                new Rule({ name: 'receiver_postal_code' }),
                new Rule({ name: 'receiver_tax_regime' }),
                new Rule({ name: 'receiver_email', type: 'email' }),
                new Rule({ name: 'receiver_credit_note_use' }),
            ],
            rules_concept: [
                //* Version avanzada de reglas
                new Rule({ name: 'quantity', value: 'number', type: 'min', min: 0 }),
                new Rule({ name: 'concept_key' }),
                new Rule({ name: 'unit_key' }),
                new Rule({ name: 'description' }),
                new Rule({ name: 'unit_value', value: 'number', type: 'min', min: 0 }),
                new Rule({ name: 'tax_object' }),
            ],
            rules_related_invoices: [
                //* Version avanzada de reglas
                new Rule({ name: 'uuid_invoice' }),
            ],
            validate: {
                valid: false,
                validations: {
                    series: null,
                    payment_form: null,
                    currency: null,
                    exchange_rate: null,
                    voucher_type: null,
                    export: null,
                    payment_method: null,
                    expedition_place: null,
                    issuing_rfc: null,
                    issuing_name: null,
                    issuing_tax_regime: null,
                    receiver_rfc: null,
                    receiver_name: null,
                    receiver_postal_code: null,
                    receiver_tax_regime: null,
                    receiver_credit_note_use: null,
                    receiver_email: null,
                },
            },
            validate_relatedInvoices_update: {
                valid: false,
                validations: {

                }
            
            },
            validate_concept: {
                valid: false,
                validations: {
                    series: null,
                    payment_form: null,
                    currency: null,
                    exchange_rate: null,
                    voucher_type: null,
                    export: null,
                    payment_method: null,
                    expedition_place: null,
                    issuing_rfc: null,
                    issuing_name: null,
                    issuing_tax_regime: null,
                    receiver_rfc: null,
                    receiver_name: null,
                    receiver_postal_code: null,
                    receiver_tax_regime: null,
                    receiver_credit_note_use: null,
                    receiver_email: null,
                },
            },
            validate_relatedInvoices: {
                valid: false,
                validations: {
                    uuid_invoice: null,
                },
            },
            customerDialog: false,
            loading: false,
            customerHelper: {
                visible: false,
            },
            creditNoteHelper: {
                visible: false,
            },
            conceptHelper: {
                visible: false,
            },
            invoiceHelper: {
                visible: false,
            },
        };
    },
    components: { ComprobanteIssuer, ComprobanteConcept, FormDropdownComplex, SerieUI, CustomerUI, FileIcon, FormInputNumber,FormDropdown,FormCalendar,FormInputSwitch,FormInputText, BasicFormToolbar, Loader, Helper, BasicDatatable, Email },
    created() {
        this.entity = new CreditNote(this.session);
    },
    async mounted() {
        await this.refresh();
        this.validate_relatedInvoices_update.validations = this.validate_relatedInvoices.validations;
        this.loading = true;
        //Cargamos la sucursal
        this.branch.id = this.session.branch;
        let myBranch = await this.branch.retrieve();
        this.branch = fillObject(this.branch, myBranch);
        if (!myBranch.certificate) {
            this.$toast.add(new ErrorToast('No se encontro ningun CSD para esta sucursal, no podrá realizar ninguna factura, favor de verificar esta informacion', { severity: "warn", summary: "Advertencia"}));
            this.branch.certificate = new BranchCertificate(this.session);
        }else{
            this.branch.certificate = fillObject(this.branch.certificate,myBranch.certificate);
            this.branch.certificate.initial_date =  new Date(this.branch.certificate.initial_date);
            this.branch.certificate.final_date =  new Date(this.branch.certificate.final_date);
        }
        //Agregamos los valores estaticos
        this.entity.issuing_rfc = this.branch.tax_number;
        this.entity.expedition_place = this.branch.zip_code;
        this.entity.issuing_name = this.branch.name.toUpperCase();
        this.entity.issuing_tax_regime = this.branch.tax_regime;
        //Aqui cargamos los CATALOGOS ESTATICOS
        this.c_MetodoPago = await satCatalogo(3);
        this.c_FormaPago = await satCatalogo(6);
        //this.c_TipoDeComprobante = await satCatalogo(13);
        this.c_Exportacion = await satCatalogo(5);
        this.c_Moneda = await satCatalogo(9);
        this.c_RegimenFiscal = await satCatalogo(2);
        this.c_UsoCFDI = await satCatalogo(15);
        this.c_TipoRelacion = await satCatalogo(14);
        this.c_TipoRelacionCancelacion = await satCatalogo(17);
        this.c_Periodicidad = await satCatalogo(18),
        this.c_Meses = await satCatalogo(19),
        (this.c_ClaveUnidad = await satCatalogo(16)), (this.loading = false);
    },
    computed: {
        emailTitle() {
            return this.entity.series + "-" + this.entity.id_credit_note;
        },
        invoicesByRFC() {
            return this.invoices.filter(x => x.receiver_rfc == this.entity.receiver_rfc)
        },
        creditNotesBySerie() {
            return this.entity.series ? this.creditNotes.filter(x => x.series == this.entity.series): this.creditNotes.filter(x => x.voucher_type == "E");
        },
        regimenFiscalReceptor() {
            return this.entity.receiver_rfc ? 
                    this.entity.receiver_rfc.length > 12 
                    ? this.c_RegimenFiscal.filter(x => x.Fisica == 'Sí')
                    : this.c_RegimenFiscal.filter(x => x.Moral == 'Sí')
                : this.c_RegimenFiscal.filter(x => x.Fisica == 'Sí');
        },
    },
    watch: {
        'is_global'(newValue, oldValue){
            this.entity.receiver_rfc = newValue ? "XAXX010101000" : oldValue;
        },
        'entity.series'(newValue) {
            this.entity.id_credit_note = newValue && !(this.entity.id) ? this.series.find((x) => x.serie == newValue && x.voucher_type == 'E').next_id : this.entity.id_credit_note;
        },
        'entity.currency'(newValue) {
            this.entity.exchange_rate = newValue == 'MXN' ? 1.00 : 0.00;
        },
        'entity.receiver_rfc'(newValue){
            this.entity.credit_note_related_invoices = [];
            if(newValue){
                this.receiver_tax_regime = null;
                if(newValue == "XAXX010101000")
                {
                    this.entity.receiver_name = "PUBLICO EN GENERAL";
                    this.entity.receiver_postal_code = this.entity.expedition_place;
                    this.entity.receiver_tax_regime = "616";
                    this.c_UsoCFDI = this.c_UsoCFDI.filter(x => x.c_UsoCFDI == 'S01' || x.c_UsoCFDI == 'CP01');
                }
                
                if(newValue == "XEXX010101000")
                {
                    this.entity.receiver_postal_code = this.entity.expedition_place;
                    this.is_global = false;
                    this.entity.receiver_tax_regime = "616";
                    this.c_UsoCFDI = this.c_UsoCFDI.filter(x => x.c_UsoCFDI == 'CP01' || x.c_UsoCFDI == 'S01');
                }
            }
        },
    },
    methods: {
        errorEmail(error) {
            this.$toast.add(new ErrorToast(error));
        },
        async certificateInvoice() {
            try {
                if (this.entity.is_certificated) throw 'Esta factura ya esta TIMBRADA'

                this.loading = true;
                let response = await this.entity.certificate();
                this.$toast.add({
                    severity: 'success',
                    summary: 'Timbrado',
                    detail: "Se ha certificado el XML con exito",
                    life: 3000,
                });
                //* Datos de certificados
                this.entity.uuid = response.uuid;
                this.entity.certification_date = response.certification_date;
                this.entity.original_chain = response.original_chain;
                this.entity.sat_certificate = response.sat_certificate;
                this.entity.sat_stamp = response.sat_stamp;
                this.entity.is_certificated = true;
            } catch (error) {
                this.$toast.add(new ErrorToast(error, {life: 7500}));
            } finally {
                this.loading = false;
            }
        },
        sendedEmail() {
            this.emailDialog.visible = false;
            this.$toast.add({
                severity: 'success',
                summary: 'Nota Credito',
                detail: 'Correo enviado con exito',
                life: 3000,
            });
        },
        sendEmail() {
            try {
                if (!this.entity.id)
                    throw "No es posible enviar correo, nota de credito no generada";
                 if (!this.entity.is_certificated) {
                     throw "No es posible enviar correo, nota de credito no certificada";
                 }
                this.emailDialog.visible = true;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        searchByRFC() {
            if (this.entity.receiver_rfc) {
                try {
                    let customer = this.customers.find(x => x.rfc == this.entity.receiver_rfc);
                    if (!customer) {
                        throw "No se encontro un cliente con el rfc " + this.entity.receiver_rfc
                    }
                    this.selectCustomer(customer);
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                }
            }
        },
        newCustomer() {
            this.entity.id_customer = null;
            this.entity.receiver_rfc = null;
            this.entity.receiver_name = null;
            this.entity.receiver_tax_regime = null;
            this.entity.receiver_postal_code = null;
            this.entity.receiver_tax_residence = null;
            this.entity.receiver_email = null;
            this.entity.receiver_credit_note_use = null;
        },
        deleteConcept(concept) {
            let id_number = concept.id_number;
            this.entity.credit_note_concepts = this.entity.credit_note_concepts.filter(x => x.id_number != concept.id_number);
            this.entity.credit_note_concepts.forEach(x => {
                if (x.id_number > id_number) {
                    x.id_number = x.id_number - 1;
                }
            });
        },
        selectConceptHelper(concept) {
            this.concept.unit_name = concept.unit_name;
            this.concept.concept_key = concept.sat_key;
            this.concept.id_concept = concept.id;
            this.concept.description = concept.description;
            this.concept.tax_object = concept.tax_object;
            this.concept.unit_key = concept.sat_unit_key;
            this.concept.unit_value = concept.unit_value;
            this.concept.iva = concept.iva_transferred;
            this.concept.ieps = concept.ieps_transferred;
            this.conceptHelper.visible = false;
        },
         seriesChanged() {
            this.entity.id_credit_note = this.series.find((x) => x.serie == this.entity.series).next_id;
        },
        selectRelatedInvoice(payload) {
            payload.update = true;
            this.relatedInvoiceDialog = true;
            this.relatedInvoiceUpdate = payload;
        },
        getEstatus(creditNote) {
            if (!creditNote.id) return 'N/A';
            else if (creditNote.is_certificated) return 'TIMBRADA';
            else return 'REGISTRADA';
        },
        selectInvoice(invoice) {
            this.relatedInvoices.uuid_invoice = invoice.uuid;
        },
        selectCustomer(customer) {
            this.entity.id_customer = customer.id;
            this.entity.receiver_rfc = customer.rfc;
            this.entity.receiver_name = customer.name;
            this.entity.receiver_tax_regime = customer.tax_regime;
            this.entity.receiver_postal_code = customer.postal_code;
            this.entity.receiver_tax_residence = customer.address + ' ' + customer.suburb + ', ' + customer.municipality + ', ' + customer.state + ', ' + customer.country;
            this.entity.receiver_email = customer.email;
            this.entity.receiver_credit_note_use = customer.use_cfdi;
        },
        async selectCreditNote(creditNote) {
            try {
                this.loading = true;
                this.entity = fillObject(this.entity, creditNote);
                if (this.entity.date) this.entity.date = new Date(this.entity.date);
                
                //* Llenamos los conceptos
                this.entity.credit_note_concepts = await this.entity.concepts();
                this.entity.credit_note_related_invoices = await this.entity.relatedInvoices();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async downloadXML() {
            this.loading = true;
            try {
                await this.entity.downloadXML();
            } catch (error) {
                this.$toast.add(new ErrorToast("No se pudo obtener el XML, favor de regenerarlo"));
            } finally {
                this.loading = false;
            }
        },
        saveConcepto(concept) {
            this.entity.credit_note_concepts.push(concept);
        },
        updateConcepto(concept) {
            this.entity.credit_note_concepts[this.entity.credit_note_concepts.findIndex(x => x.id_number == concept.id_number)] = concept;
        },
         actualizaRelatedInvoices() {
             try {
                this.validate_relatedInvoices_update = validate(this.relatedInvoiceUpdate, this.rules_related_invoices);
                if (!this.validate_relatedInvoices_update.valid) throw 'Favor de validar los campos';
                
                  let relatedInvoices = {
                    ...this.relatedInvoices,
                };
              
                //Actualizamos el index
                let index = this.entity.credit_note_related_invoices.findIndex(x => x.uuid_invoice == relatedInvoices.uuid_invoice);
                this.entity.credit_note_related_invoices[index] = relatedInvoices;
                this.relatedInvoiceDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        saveRelatedInvoices() {
            try {
               
                this.validate_relatedInvoices = validate(this.relatedInvoices, this.rules_related_invoices);
                if (!this.validate_relatedInvoices.valid) throw 'Favor de validar los campos';
                let relatedInvoices = {
                    ...this.relatedInvoices,
                };
                
                this.entity.credit_note_related_invoices.push(relatedInvoices);
                    this.relatedInvoices = new CreditNoteRelatedInvoices(this.session);
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                }
        },
        async openNew() {
            try {
                this.loading = true;
                this.entity = new CreditNote(this.session);
                this.concept = new CreditNoteConcept(this.session);
                this.branch.id = this.session.branch;
                this.branch = fillObject(this.branch, await this.branch.retrieve());
                //Agregamos los valores estaticos
                this.entity.issuing_rfc = this.branch.tax_number;
                this.entity.issuing_name = this.branch.name.toUpperCase();
                this.entity.issuing_tax_regime = this.branch.tax_regime;
                this.entity.expedition_place = this.branch.zip_code;
                this.newDialog = true;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async downloadPDF() {
             try {
                this.loading = true;
                await this.entity.downloadPDF();
            } catch (error) {
                this.$toast.add(new ErrorToast("No se pudo obtener el PDF, " + error));
            } finally { 
                this.loading = false;
            }
        },
        updateCustomer(customer) {
            this.customerDialog = false;
            this.selectCustomer(customer);
            this.customers[this.customers.findIndex(x => x.id == customer.id)] = customer;
        },
        saveCustomer(customer) {
            this.customerDialog = false;
            this.selectCustomer(customer);
            this.customers.push(customer);
        },
        saveSerie(serie) {
            this.serieDialog = false;
            this.series.push(serie);
            this.entity.voucher_type = serie.voucher_type;
            this.entity.series = serie.serie;
            this.entity.id_credit_note = serie.next_id;
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) throw 'Favor de validar los campos. [' + this.validate.message + ']';
                if (!this.entity.id_customer) throw 'Favor de seleccionar un cliente';
                if (this.entity.is_certificated) throw 'No se puede modificar una nota de credito ya TIMBRADA'
                if(this.entity.credit_note_related_invoices.length == 0 ) throw 'Debe relacionar al menos una factura a la nota de credito.'

                if (this.entity.id) {
                    let invoice = await this.entity.regenerate();
                    this.entity.taxes_total_detained = invoice.taxes_total_detained;
                    this.entity.taxes_total_transferred = invoice.taxes_total_transferred; 
                    this.entity.subtotal = invoice.subtotal;
                    this.entity.discount = invoice.discount;
                    this.entity.taxes_total = invoice.taxes_total_detained + invoice.taxes_total_transferred;
                    this.entity.total = invoice.total;
                    this.entity.xmLfilename = invoice.xmLfilename;
                    this.entities[this.entities.findIndex(x => x.id == this.entity.id)] = {...this.entity};
                }else {
                    let invoice = await this.entity.generate();
                    this.entity.id_number = invoice.id_number;
                    this.entity.id = invoice.id;
                    this.entity.taxes_total_detained = invoice.taxes_total_detained;
                    this.entity.taxes_total_transferred = invoice.taxes_total_transferred; 
                    this.entity.subtotal = invoice.subtotal;
                    this.entity.discount = invoice.discount;
                    this.entity.taxes_total = invoice.taxes_total_detained + invoice.taxes_total_transferred;
                    this.entity.total = invoice.total;
                    this.entity.xmLfilename = invoice.xmLfilename;
                    this.invoices.push(invoice);
                }
                this.$toast.add({
                    severity: 'success',
                    summary: 'Nota de Credito',
                    detail: 'Informacion actualizada con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async certificateCreditNote() {
            try {
                this.loading = true;
                let response = await this.entity.certificate();
                this.$toast.add({
                    severity: 'success',
                    summary: 'Timbrado',
                    detail: response.mensaje,
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new CreditNote(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
                this.$emit('deleted');
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            try {
                //Si tiene un id lo cargamos
                if (this.id) {
                    this.entity.id = this.id;
                    let retrieved = await this.entity.retrieve();
                    fillObject(this.entity, retrieved);
                    if (this.entity.date) this.entity.date = new Date(this.entity.date);
                }
                this.customers = await new Customer(this.session).all();
                this.invoices = (await new Invoice(this.session).all()).filter(x => x.uuid);
                this.creditNotes = await new CreditNote(this.session).all();
                this.series = await new InvoiceSerie(this.session).all();
                this.series = this.series.filter(x => x.voucher_type == 'E');
                
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
    },
};
</script>

<style scoped lang="scss">

.custom-tool .p-tooltip-text {
    background-color: var(--gray-800);
    color: rgb(255, 255, 255);
}
.custom-tool .p-tooltip-top .p-tooltip-arrow {
    border-right-color: var(--gray-800);
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
